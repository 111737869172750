.app-loader-logo {
  /* height: 150px; */
  pointer-events: none;
  opacity: 100%;
}


@media (prefers-reduced-motion: no-preference) {
  .app-loader-logo {
    animation: loader-logo-spin infinite 20s linear;
  }
}

@keyframes loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
