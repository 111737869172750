.admin-tablerow {
  padding: 2%;
  color: #050505;
  align-items: self-start;
}
.admin-tableheader {
  padding: 2%;
  width: 100%; 
  color: #050505;
  background-color: #F3F5F6;
  align-items: self-start;
  position: sticky;
  top: 0;
}

.admin-table {
  display: block;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 100%;
  overflow: auto;
  position: relative;
}
/* 
.admin-table::-webkit-scrollbar {
  display: block;
  width: 0.6em;
}
.admin-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.admin-table::-webkit-scrollbar-thumb {
  background-color: lightgray;
  outline: 0;
  border-radius: 40px;
  height: 20%;
} */

.admin-tablecell {
    padding: 0 2%;
}

.admin-tableheadercell {
    padding: 0 2%;
}
