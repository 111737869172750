.card-container{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    width: 100%;
    height: auto;
    padding: 1% 0;
    scroll-behavior: smooth;
    justify-content: flex-start;
}

.card-container::-webkit-scrollbar{
    display: none;
}

.card-container-item{
    justify-content: center;
}

.goal-card{
    border-radius: 16px;
    background-color: white;
    display: block;
    padding: 2%;
    width: 21vw;
    min-width: 312px;
    min-height: 556px;
    height: 38vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: max(1vw, 10px);
}

.one-line-only {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

.two-lines-only {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
  
