.myButton {
    font-size: 16px !important;
    width: 100% !important;
    height: 110% !important;
    text-transform: none !important;
    font-weight: bold !important;
    border-radius: 40px !important;
    padding: 10px 0 !important;
  }
  
  a {
    color: #643992;
  }
  
  h1 {
    margin: 2px;
    font-weight: bold;
  }
  
  p {
    margin-top: 0;
    color: lightgray;
  }
  
  label {
    font-size: max(14px, 0.972vw);
    font-weight: bold;
    color: #696971;
  }
  
  input {
    width: 100%;
    align-self: center;
    background-color: #eceff4;
    font-size: max(14px, 0.972vw);
    font-family:Roboto, sans-serif;
    border-radius: 12px;
    border: 0;
    padding: 14px 16px;
    margin-bottom: 24px;
    margin-top: 1%;
  }
  
  input:placeholder-shown {
    color: #b9bcc3;
    font-style: italic;
  }
  
  
  .password {
    margin-bottom: 0;
  }
  
  .forgotpassword {
    color: #643992;
    text-align: right;
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: lighter;
    cursor: pointer;
  }

  .forgot-button{
    border: 0;
    padding: 0!important;
    margin-top: 5px !important;
    font-size: max(12px, 0.83vw) !important;
  }

  .forgot-button:hover{
    background-color: transparent !important;
  }

  .forgot-button:focus{
    background-color: transparent !important;
  }
  
  .text {
    text-align: center;
    color: #696971;
    margin: 30px 0;
  }
  
  .textField {
    text-align: center;
    color: #000;
    margin: auto;
    font-weight: bold;
  }
  
  .orbutton {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  hr {
    height: 1px;
    width: 40%;
    align-self: center;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  
  .square {
    height: 10px;
    width: 10px;
    background-color: #555;
    align-self: center;
    margin: 0 10px;
  }

  .close-button {
    position: absolute;
    top: 0;
    left: 0;
  }

  .dashed-line{
    height: 1px;
    border-top: 1px #D6DAE4 dashed;
    margin: 4% 0;
  }

  .bold-text{
    font-weight: bold;
  }