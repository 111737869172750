.masonry-dashboard {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  flex-direction: row;
  margin-left: -10px; /* gutter size offset */
  width: auto;
  margin-right: 0%;
  margin-bottom: 2%;
  background-color: #f3f4f6;
  grid-gap: 1%;
}
.masonry-columns {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}
.masonry-flexbox{
  height: 44vw;
  min-height: 645px;
}

@media only screen and (max-width: 1204px){
  .masonry-dashboard{
    height: auto;
    margin-bottom: 10%;
  }
  .masonry-dashboard{
    height: auto;
  }
  .masonry-item{
    margin-bottom: 5%;
  }
  .masonry-flexbox{
    height: auto;
    margin-bottom: 5%;;
  }

  .treemap-container{
    height: auto !important;
  }
}


@media only screen and (max-width: 600px){
  .masonry-item{
    border-radius: 0 !important;
  }
}

/* Style your items */
.masonry-columns > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 10px;
}

.legendBox {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.legend-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-row-gap: 10%;
  grid-column-gap: 3%;
  margin: auto;
  margin-top: 10px;
}

.legend-text{
  text-overflow: ellipsis;
}

.legend-grid-subcategory{
  margin: auto 0;
  margin-top: 4%;
}

.legend-grid-item{
  display:flex;
  flex-direction: row;
  align-items: center; 
  justify-content: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dashed-line{
  height: 1px;
  background-color: transparent;
  border-top: 1px dashed #D6DAE4;
  width: 100%;
}

.benefits-grid{
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: auto;
  margin-top: 3%;
}