.calendar-grid {
  display: flex;
  flex-direction: row;
  height: auto;
}

.calendar-column {
  display: flex;
  flex-direction: column;
}

.category-color-box {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.category-bar-item{
  display:flex;
  flex-direction: row;
  align-items: center; 
  justify-content: left;
  width: 'auto';
  white-space: nowrap;
  margin: 0 1%;
}

.calendar-select{
  border-radius: 4px;
  background-color: white;
  padding: 6%;
  width: 80%;
  height: 100%;
  margin: auto;
  border: 0;
  font-size: max(14px, 0.97vw);
  text-transform: capitalize;
}

.calendar-options{
  border: 0;
  font-size: max(14px, 0.97vw);
  padding: 4%;
}

.calendar-heading-select{
  border-radius: 4px;
  background-color: transparent;
  height: 100%;
  border: 0;
  font-size: max(14px, 0.97vw);
  text-transform: capitalize;
}

.calendar-heading-select:focus,.calendar-heading-select:hover{
  outline: none;
}

.calendar-heading-options{
  border: 0;
  font-size: max(14px, 0.97vw);
  padding: 4%;
}