.loader-logo {
  height: 150px;
  pointer-events: none;
  filter: invert(0.87);
  opacity: 100%;
}

.loader-logo-no-invert{
  height: 120px;
  pointer-events: none;
  opacity: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .loader-logo {
    animation: loader-logo-spin infinite 20s linear;
  }
  .loader-logo-no-invert {
    animation: loader-logo-spin infinite 20s linear;
  }
}

@keyframes loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  position: absolute;
  top:0;
  right: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  /* transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%); */
  background-color: black;
  opacity: 50%;
}

.loader-box-container {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  /* transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%); */
}
