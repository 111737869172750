body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
}

div{
  scrollbar-width: thin;
  scrollbar-color: #E0E4EF transparent;
}

div::-webkit-scrollbar {
  display: block;
  width: 0.4em;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div::-webkit-scrollbar-thumb{
  background-color: #E0E4EF;
  outline: 0;
  border-radius: 40px;
  height: 40;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
